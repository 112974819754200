div#comments {
  background: $white;
  border: solid 1px rgba($grey, .5);
  padding: 15px;

  @media(max-width: 767px) {
    margin-bottom: 30px;
  }

  & > h2 {
    font-size: $p-fs;
    font-family: $ff-copy;
  }

  h3#reply-title {
    font-size: $h5-fs;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 30px;
  }

  label {
    display: block;
    font-size: $fs-xs;
    margin-bottom: 10px;
    color: $black;
  }

  input, textarea {
    background: $off-white;
    border: solid 1px rgba($grey, .5);
  }

  span.required {
    color: $red;
  }

  textarea#comment {
    width: 100%;

    @media(max-width: 767px) {
      height: 120px;
    }
  }

  p.comment-form-author, p.comment-form-email {
    display: inline-block;
    width: 50%;
    vertical-align: top;

    @media(max-width: 767px) {
      display: block;
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    input {
      width: 100%;
    }
  }

  p.comment-form-author {
    margin-right: -6px;
    padding-right: 15px;
  }

  p.comment-form-email {
    padding-left: 15px;
  }

  p.form-submit {
    text-align: center;
    padding-top: 15px;

    input {
      background: $black;
      color: $white;
      border: none !important;
      display: inline-block;
      vertical-align: middle;
      @include transition(background .3s ease-in-out);
      font-family: $ff-accent;
      padding: 5px 8px;
      max-width: none;
      font-size: $fs-xs !important;
      font-weight: lighter !important;

      &:hover {
        background: $pblack;
      }
    }
  }



  ol.comment-list {
    list-style: none;
    padding: 0;

    li {
      padding: 15px;
      background: $off-white;
      border: solid 1px rgba($grey, .5);
      position: relative;
    }
  }

  .comment-author.vcard, div.comment-metadata {
    display: inline-block;
    vertical-align: bottom;
  }



  div.comment-metadata {
    font-weight: normal;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 5px 0;
    display: block;
    line-height: 1;
    background: $white;
    border: 1px solid $grey;

    a, time {
      font-size: 10px;
      display: inline-block;
      vertical-align: top;
      color: $black;
    }



    a.bt-anchor {
      color: rgba($black, .75);
    }
  }

  .comment-content {
    padding: 15px;
    background: $white;
    border: solid 1px $grey;
    margin: 15px 0;

    p {
      font-size: $h5-fs;
      font-style: italic;
    }
  }

  em.comment-awaiting-moderation {
    display: block;
    margin-top: 30px;
    background: rgba($red, .45);
    color: $black;
    padding: 5px 15px;
    line-height: 1.4;
    font-size: $fs-xs;
    text-align: center;
  }


  div.reply {
    text-align: right;

    a {
      color: $black;
      font-size: $fs-sm;
      text-decoration: underline;
    }
  }


  ol.comment-list + div#respond {
    border-top: solid 1px $grey;
    margin-top: 30px;
  }


}//end comments