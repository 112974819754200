
aside#blog-sidebar {

  background: $white;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  padding: 15px;

  div.widget {

    margin-bottom: 0;

    & + div.widget {
      margin-top: 30px;
      border-top: solid 1px $off-white;
      padding-top: 30px;
    }

    h4 {
      font-size: $p-fs;
      font-weight: bold;
    }

  }


  div.widget_search {

    text-align: right;
    


    label {
      width: 100%;
    }


    input[type="search"] {
      background: $off-white;
      border: solid 1px rgba($grey, .5);
      width: 100%;
      max-height: none;
      margin-bottom: 8px;
      text-align: left;
    }



    input[type="submit"] {
      width: 90px;
      background: $black;
      color: $white;
      border: none !important;
      display: inline-block;
      vertical-align: middle;
      @include transition(background .3s ease-in-out);
      font-family: $ff-accent;
      padding: 2px 5px;
      max-width: none;
      font-size: $fs-xs;
      font-weight: lighter;

      &:hover {
        background: $pblack;
      }
    }
  }



  ul.custom-recent-posts {
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 15px;
      display: table;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a.custom-recent-post-tn {
      float: left;
      margin: 0 15px 0 0;
      width: 80px;
      height: 80px;

      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        float: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;

        @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
          height: 90px;
        }
      }
    }

    small {
      font-weight: normal;
      color: $grey;
      font-size: $fs-xs;
    }

    h5 {
      font-size: $fs-sm;
      font-weight: bold;
      text-transform: uppercase;

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        font-size: $fs-xs;
      }

      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
      	font-size: $fs-sm;
      }

      a {
        color: $black;
      }
    }

  }



  ul.custom-blog-categories {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      position: relative;
      display: block;
      margin-bottom: 0;
      overflow: hidden;


      &:before {
        display: inline-block;
        font-family: "Ionicons";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f125";
        color: rgba($black, .5);
        width: 30px;
        display: inline-block;
        vertical-align: middle;
        font-size: $fs-sm;
        margin-left: 0;
        @include transition(color .3s ease-in-out);
      }


      & + li {
        margin-top: 5px;
      }



      a {
        overflow: hidden;
        font-size: $fs-sm;
        display: inline-block;
        vertical-align: middle;
        color: $black;
        position: relative;

        &:before, &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          @include transition(all .185s ease-in-out);
          bottom: 0;
        }

        &:before {
          left: -100%;
          background: $black;
        }

        &:after {
          left: 0;
          background: $white;
        }

        &:hover {
          &:before {
            left: 0;
          }

          &:after {
            left: 100%;
          }
        }
      }

    }


  }//end custom blog cats


  //MailChimp Subscribe sidebar widget
  .widget.widget_mc4wp_form_widget {

    text-align: right;

    h4 {
      text-align: left;
    }

    input[type=email] {
      background: $off-white;
      border: solid 1px rgba($grey, .5);
      width: 100%;
      max-height: none;
      margin-bottom: 8px;
      text-align: left;
    }

    input[type=submit] {
      width: 90px;
      border: none !important;
      display: inline-block;
      vertical-align: middle;
      @include transition(background .3s ease-in-out);
      font-family: $ff-accent;
      padding: 2px 5px;
      max-width: none;
      font-size: $fs-xs;
      font-weight: lighter;
      text-transform: none;
    }
  }//MailChimp Subscribe sidebar widget


}