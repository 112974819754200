header.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 15px 0;
  z-index: 999;
  background: $white;
  @include transition(all $trans);

  a.navbar-brand {
    margin: 0;
    padding: 0;
  }

  div#logo {
    img {
      width: 150px;
      height: auto;
      @include transition(width $trans);

      @media(max-width: 767px) {
        width: 120px;
      }
    }
  }



  div.lang-switcher {

    @media(max-width: 767px) {
      position: relative;
      right: -10px;
    }

    ul {
      display: inline-block;
    }


    ul.wpml-ls-sub-menu {
      border-top: none;
      background: $white;
    }


    .wpml-ls-legacy-dropdown-click a {
      background: transparent;
      color: $c-primary;
      border: none;
      @include transition(all $trans);
      text-align: left;

      &:hover {
        background: $c-primary;
        color: $white;
      }
    }

  }//end lang-switcher




}//end header



//Active styles
body.header-active {

  padding-top: 63px;

  header.header {
    @include box-shadow-default;

    div#logo {
      img {
        width: 90px;
        height: auto;
      }
    }
  }

}
