a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

a.anchor-underline {
  color: $black;
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-size: $fs-sm;

  &:before, &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    @include transition(all .185s ease-in-out);
    bottom: 0;
  }

  &:before {
    left: -100%;
    background: $black;
  }

  &:after {
    left: 0;
    background: $white;
  }

  &:hover {
    &:before {
      left: 0;
    }

    &:after {
      left: 100%;
    }
  }

}