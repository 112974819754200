
body.search {

  background: $off-white;

  header.header {
    background-color: $black !important;
    padding: 15px 0 !important;
  }

  section#blog-landing {
    margin-top: 105px;
    padding-top: 0;

    @media(max-width: 767px) {
      margin-top: 60px;
    }
  }


  .single-blog-post.search-no-results {

    background: $white;
    border: 1px solid hsla(0,0%,82%,.5);
    padding: 15px 15px 30px;
    width: 100%;

    @media(max-width: 767px) {
      margin-bottom: 30px;

      label {
        width: 100%;
      }

    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-bottom: 30px;
    }

    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      margin-bottom: 0;
    }

    input[type=search] {
      background: $off-white;
      border: 1px solid hsla(0, 0%, 82%, .5);
      width: 100%;
      max-height: none;
      margin-bottom: 0;
      text-align: left;

      @media(max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    input[type=submit] {
      width: 90px;
      background: $black;
      color: $white;
      display: inline-block;
      vertical-align: top;
      transition: background .3s ease-in-out;
      font-family: $ff-accent;
      padding: 5px 8px;
      max-width: none;
      font-size: 12px;
      font-weight: lighter;
      border: 1px solid $black;

      @media(max-width: 767px) {
        width: 100%;
        max-width: none;
      }
    }
  }//end .single-blog-post.search-no-results


  header.search-header {
    h1 {
      font-size: $h3-fs;
      text-transform: none;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 30px;

      strong {
        font-size: inherit;
        font-weight: bold;
        text-transform: uppercase;
        color: $c-primary;
      }

      @media(max-width: 767px) {
        margin-top: 30px;
      }
    }
  }


}//body.search

































