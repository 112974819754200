

footer#footer {

  background: $bright-blue;
  padding: 8px 0;

  @media (max-height: 1024px) and (orientation: portrait) {
    padding: 15px 0;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    padding: 15px 0;
  }

  @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    padding: 15px 0;
  }


  a {
    color: $white;
  }

  span.color-bb {
    color: $turqouise;
  }

  span.link-separator {
    color: $white;
  }

  ul.footer-items {

    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: inline-block;
      vertical-align: middle;
      font-size: $fs-xs;

      &.li-img-logo + li.li-link {
        margin-left: 15px;
      }

      &.li-link + li.li-email-phone {
        margin-left: 15px;
      }


      &.li-email-phone + li.li-img-social {
        margin-left: 15px;
      }


      &.li-img-logo {
        @media (max-height: 1024px) and (orientation: portrait) {
          margin-bottom: 15px;
        }
      }


      &.li-link {

        img, span.link-label {
          vertical-align: middle;
        }

        @media (max-height: 1024px) and (orientation: portrait) {
          display: block!important;
          width: 100%;
          margin: 0!important;

          &+ .li-link + .li-link {
            margin-bottom: 15px!important;
          }

          span.link-separator {
            display: none;
          }
        }
      }


      &.li-email-phone {
        @media (max-height: 1024px) and (orientation: portrait) {
          display: block!important;
          width: 100%;
          margin: 0!important;

          & + .li-email-phone {
            margin-bottom: 15px!important;
          }
        }


        @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        	display: block!important;
          width: 100%;
          margin: 15px 0 0!important;

          & + .li-email-phone {
            margin-bottom: 15px!important;
            margin-top: 0!important;
          }
        }


        @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          display: block!important;
          width: 100%;
          margin: 15px 0 0!important;

          & + .li-email-phone {
            margin-bottom: 15px!important;
            margin-top: 0!important;
          }
        }


      }


      &.li-img-social {

        @media(max-width: 767px) {
          margin-left: 0!important;
        }

        @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          margin-left: 0!important;
        }
      }

    }//end li


    img.img-logo {
      width: 100%;
      height: 55px;
      object-fit: contain;
      object-position: center;
    }

  }//end footer-items



















}
