
.modal {

  &.fade {
    @include opacity(0, 0);
    @include transition(opacity .15s linear);

    .modal-dialog {
      transition: -webkit-transform .3s ease-out;
      transition: transform .3s ease-out;
      transition: transform .3s ease-out, -webkit-transform .3s ease-out;
      -webkit-transform: translate(0, -25%);
      transform: translate(0, -25%);
    }

    &.show {
      @include opacity(100, 1);

      .modal-dialog {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
      }
    }
  }

}//end modal


.modal-backdrop.fade {
  @include transition(opacity .15s linear);
}
















