
body.blog {
  background: $off-white;

  section#hero {
    /*min-height: 65vh;*/

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      h1 {
        font-size: $h2-fs;
        margin-top: 0;
      }
    }

    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      h1 {
        font-size: 90px;
        margin-top: 75px;
      }
    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      h1 {
        font-size: $h2-fs;
        margin-top: 0;
      }
    }

  }


}

article.blog {
  min-height: 200px;
  margin-bottom: 30px;
  overflow: hidden;
  background: $white;
  border: solid 1px rgba($grey, .5);
}

article.blog figure {
  height: 350px;
  overflow: hidden;
  position: relative;
  margin-bottom: 0;

  @media(max-width: 767px) {
    height: 210px;
  }

  @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    height: 240px;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    height: 320px;
  }
}

article.blog figure img {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  -moz-transition: 0.65s;
  -webkit-transition: all 0.65s ease-in-out;
  transition: all 0.65s ease-in-out;
  width: auto;
  height: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;

  @media(max-width: 767px) {
    height: 320px;
  }

  @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: 105%;
    height: auto;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    height: 350px;
  }
}

article.blog figure img:hover {
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
}

article.blog figure .preview {
  position: absolute;
  top: 50%;
  left: 0;
  margin: -12px auto 0;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  z-index: 2;
  right: 0;
  max-width: 90px;
}

article.blog figure .preview span {
  background-color: transparent;
  display: inline-block;
  color: $white;
  font-size: $fs-xs;
  padding: 5px 10px;
  border: solid 1px $white;
  @include transition(all .3s ease-in-out);
  width: 100%;

  &:hover {
    background: $black;
  }
}

article.blog .post_info {
  padding: 15px 30px;
  position: relative;
  box-sizing: border-box;
  height: 100%;

  @media(max-width: 767px) {
    padding: 30px 15px;
  }
}

article.blog .post_info small {
  font-weight: 500;
  color: #999;
}

article.blog figure .preview {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  z-index: 2;
  background: rgba($black, .25);
}

article.blog figure:hover .preview {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

/////////

article.blog {

  ul.bt-post-meta {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  small {
    font-weight: normal!important;
    color: $grey!important;

    li.post-category {
      display: none;

      i {
        display: none;
      }
    }

    li.post-date {
      font-weight: normal!important;
      color: $grey!important;
      font-size: $fs-xs;
      margin-bottom: 5px;
      i {
        display: none;
      }
    }

    li.post-author {
      display: none;

      i {
        display: none;
      }
    }
  }

  h3 {
    font-size: $h4-fs;
    font-weight: bold;
    text-transform: uppercase;

    a {
      color: $black;
    }
  }

  p.post-excerpt {
    margin-bottom: 30px;
    font-size: $fs-sm;
  }

  a.cta {
    max-width: none;
    width: 100%;

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      max-width: 150px;
      width: 100%;
      margin: 0 auto;
      display: block;
    }

    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      max-width: none;
      margin-left: 0;
      display: inline-block;
    }
  }

}

//end article.blog




















