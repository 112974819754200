$base-fs: 15px;
body{
  font-size:$base-fs;
  color: $black;
  font-family: $ff-copy;
}


$h1-fs: 3.998em;
$h2-fs: 2.827em;
$h3-fs: 1.999em;
$h4-fs: 1.414em;
$h5-fs: 18px;
$p-fs:  1em;
$fs-sm: 14px;
$fs-xs: 12px;
$p-lh: 26px;

h1{
  font-size: $h1-fs;
  font-weight: bold;
  font-family: $ff-heading;
  color: $c-primary;
  @media(max-width: 767px) {
    font-size: $h2-fs;
  }
}

h2{
  font-size: $h2-fs;
  font-weight: bold;
  font-family: $ff-heading;
  margin-bottom: 30px;
  color: $c-primary;
  @media(max-width: 767px) {
    font-size: $h3-fs;
  }
}

h3{
  font-size: $h3-fs;
  font-family: $ff-copy;
  margin-bottom: 15px;
  color: $c-primary;

  @media(max-width: 767px) {
    font-size: $h4-fs;
  }

  @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: $h4-fs;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  	font-size: $h3-fs;
  }
}

h4{
  font-size: $h4-fs;
  font-family: $ff-copy;
  margin-bottom: 15px;
}

p, ul li, label, input, textarea, strong, button{
  font-size: $base-fs;
  font-family: $ff-copy;
}
p {
  margin-bottom: 0;
  & + p {
    margin-top: 15px;
  }
}
