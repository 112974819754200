



ul#menu-main-menu {
  float: none;
  margin: 0 auto;
  padding: 0;

  li {
    float: left;
    margin: 0;
    line-height: 1;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
      position: relative;
      top: 0;
    }

    a {
      color: $white;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: .5px;
      font-size: $fs-sm;
      font-family: $ff-accent;
      @include transition(all .3s ease-in-out);

      &:hover {
        color: $white;
      }

    }//end menu a


    &:not(.menu-item-has-children) {

      a {
        position: relative;
        display: inline-block;
        overflow: hidden;

        &:before, &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          @include transition(all .185s ease-in-out);
          bottom: 0;
        }

        &:before {
          left: -100%;
          background: $white;
        }

        &:after {
          left: 0;
          background: transparent;
        }
      }

      &:hover {
        a {
          &:before {
            left: 0;
          }

          &:after {
            left: 100%;
          }
        }
      }

    }//not has children

  }
}//end menu-main-menu




//Drop down menu
div.dropdown-menu {
  background: $pblack;
  border-radius: 0;
  left: 0;
  top: calc(100% - 10px)!important;
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden;
  max-height: 0;
  @include transition(all .3s ease-in-out);
  display: block;
  margin-top: 10px;
}//end dropdown-menu


nav.navbar.navbar-toggleable-sm.navbar-expand-lg {

  @media(max-width: 767px) {

    background: $pblack;
    margin: 0 -15px;
    padding: 0 15px;
    top: 10px;

    div.navbar-collapse {
      width: 100%;
      text-align: left;
      position: relative;
      display: block;
      overflow: hidden;

      &.collapse {
        max-height: 0;
      }

      @include transition(all .65s ease-in-out);

      &:not(.collapse) {
        max-height: 1000px;
      }

    }//end navbar collapse

    ul#menu-main-menu {

      li {
        float: none;
        display: block;
        margin: 0;
        line-height: normal;

        a.nav-link {
          display: block;
        }

        & + li {
          padding-top: 5px;
          margin-top: 5px;
          border-top: 1px solid $black;
        }


        &:last-child {
          padding-bottom: 5px;
        }


        &.dropdown {
          & > a {

            margin-top: 15px;

            &:after {
              display: none;
            }
          }


          div.dropdown-menu {
            display: block;
            margin-top: 5px;
            height: auto;
            position: relative!important;
            overflow: visible;
            max-height: none;

            a.dropdown-item {
              position: relative;
              display: flex!important;
              padding: 0;
              flex-direction: row;

              & + a.dropdown-item {
                margin-top: 15px;
                padding-top: 15px;
              }

              &:last-child {
                margin-bottom: 10px;
              }

              span {
                display: flex;
                align-items: center;

                &.img-wrap {
                  order: 2;
                  -ms-flex: 0 0 25%;
                  flex: 0 0 25%;
                  max-width: 25%;
                  height: 60px;
                }


                &.nav-text {
                  order: 2;
                  padding: 0 0 0 15px;
                  -ms-flex: 0 0 75%;
                  flex: 0 0 75%;
                  max-width: 75%;
                }

              }//end span



            }//end a dropdown-item
          }//end dropdown menu


        }//end dropdown

      }//end li

    }//end menu-main-menu

  }//end mobile


  @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {

    background: $pblack;
    margin: 0 -15px -5px;
    padding: 0 15px;
    top: 10px;

    div.navbar-collapse {
      width: 100%;
      text-align: left;
      position: relative;
      display: block;
      overflow: hidden;

      &.collapse {
        max-height: 0;
      }

      @include transition(all .65s ease-in-out);

      &:not(.collapse) {
        max-height: 1000px;
      }

    }
    //end navbar collapse

    ul#menu-main-menu {

      li {
        float: none;
        display: block;
        margin: 0;
        line-height: normal;

        a.nav-link {
          display: block;
        }

        & + li {
          padding-top: 5px;
          margin-top: 5px;
          border-top: 1px solid $black;
        }

        &:last-child {
          padding-bottom: 5px;
        }

        &.dropdown {
          & > a {

            margin-top: 15px;

            &:after {
              display: none;
            }
          }

          div.dropdown-menu {
            display: block;
            margin-top: 5px;
            height: auto;
            position: relative !important;
            overflow: visible;
            max-height: none;

            a.dropdown-item {
              position: relative;
              display: inline-block !important;
              padding: 0;
              width: calc(100% / 3);
              vertical-align: top;
              text-align: center;

              span {
                display: block;

                &.img-wrap {
                  display: block;
                  height: 120px;
                  max-width: none;
                  width: 100%;
                  padding: 15px 15px 0;
                }

                &.nav-text {
                  padding: 0;
                  max-width: none;
                  margin-top: 10px;
                  margin-bottom: 5px;
                }

              }
              //end span

            }
            //end a dropdown-item
          }
          //end dropdown menu

        }
        //end dropdown

      }
      //end li

    }
    //end menu-main-menu

  }//end tab PT


  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {

    background: transparent;
    margin: 0;
    padding: .5rem 1rem;
    top: 0;
    position: relative;

    div.navbar-collapse {
      max-height: none;
      overflow: visible;

      &.collapse {
        max-height: none;
      }

      @include transition(none);

      &:not(.collapse) {
        max-height: none;
      }
    }


    ul#menu-main-menu {

      li {
        float: left;
        margin: 0;
        line-height: 1;
        margin-right: 15px;
        display: block;

        & + li {
          padding-top: 0;
          margin-top: 0;
          border-top: none;
        }

        &.menu-item-has-children.dropdown {

          &:hover {
            div.dropdown-menu {
              max-height: 450px;
            }
          }

          a {
            margin-top: 0;

            &:after {
              display: inline-block;
            }
          }


          div.dropdown-menu {
            display: block;
            height: auto;
            position: absolute!important;
            overflow: hidden;
            min-width: 350px;
            left: 0;
            top: calc(100% - 10px)!important;
            margin: 0;
            padding: 0;
            border: none;
            max-height: 0;
            transition: all .3s ease-in-out;
            margin-top: 10px;




            a.dropdown-item {
              position: relative;
              padding: 10px 20px 10px 10px;
              display: block!important;
              width: 100%;
              text-align: left;

              span {
                display: inline-block;
                vertical-align: middle;

                &.nav-text {
                  padding-left: 15px;
                }
              }
            }
          }

        }//end li dropdown







      }//end li


    }








  }//end tab pt lg

}//end mobile navmenu







//List item has children
ul li.menu-item-has-children.dropdown {
  @include transition(all .65s ease-in-out);

  &:hover {
    background: $pblack;
    div.dropdown-menu {
      max-height: 450px;
    }
  }
}//List item has children


//Contact button
a.main-menu-contact.cta.ghost.sm {
  color: $white;
  display: block;
  max-width: 100px;
  margin-left: auto;
}














//Mobile nav menu

.hamburger {
  padding: 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  display: none;

  @media(max-width: 767px) {
    display: inline-block;
  }

  @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    display: block;
    top: 1px;
    position: relative;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    display: none;
  }
}

.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 30px;
  height: 4px;
  background-color: $white;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}








































