

body.page-template-front-page {



  section#hero {

    position: relative;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 60px 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background: rgba($c-primary, 0.45);
      z-index: 1;

      @media(max-width: 767px) {
        display: none;
      }
    }//end before


    div.wrapper {
      position: relative;
      z-index: 5;
    }


    div.heading-wrap {
      padding-right: 75px;

      @media(max-width: 767px) {
        padding-right: 0;
        text-align: center;
      }

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        padding-right: 0;
      }

      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        padding-right: 0;
      }

    }


    h1 {
      text-transform: uppercase;
      color: $white;
      margin: 0;

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        font-size: $h2-fs;
      }

      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        font-size: $h1-fs;
      }

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        font-size: $h2-fs;
      }

      span {
        display: inline-block;
        /*background: $c-primary;*/
        margin-bottom: 5px;
        line-height: 1;
        padding: 2px 10px;
      }
    }


    div.img-wrap {

      margin-top: 30px;

      img {
        width: 120px;

        @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
          width: 120px;
        }

        @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
          width: 120px;
        }

        @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          width: 100px;
        }
      }
    }


    div.form-wrap {

      text-align: center;
      padding-left: 75px;
      width: 100%;

      @media (min-width: 1450px) {
        width: calc(100% + 150px);
      }

      @media(max-width: 767px) {
        width: 100%;
        background: rgba($c-primary, 0.65);
        margin-top: 45px;
        padding: 30px 15px;
      }


      @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        padding-left: 0;
      }


      h2, p {
        color: $white;
      }


      h2 {
        margin-bottom: 15px;
        font-size: 50px;
        letter-spacing: 1px;
        line-height: 1;

        @media(max-width: 767px) {
          font-size: $h2-fs;
        }

        @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
          font-size: $h2-fs;
        }

        @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
          font-size: 50px;
        }

        @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          font-size: $h2-fs;
        }
      }


      h2 + p {
        text-transform: uppercase;
        font-size: 25px;
        margin-bottom: 30px;

        @media(max-width: 767px) {
          font-size: $h4-fs;
        }

        @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
          font-size: $h5-fs;
        }

        @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
          font-size: 25px;
        }

        @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          font-size: $h5-fs;
        }

      }

      div.wpcf7 {
        text-align: left;
      }//end wpcf7

    }//end .form-wrap


  }//end section#hero


  section#content-section-1, section#content-section-2 {

    @media(max-width: 767px) {
      padding-bottom: 0;
    }

    h2 {
      font-size: $h3-fs;
    }

    div.bg-holder {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;

      @media (min-width: 1450px) {
        right: 150px;
        max-width: calc(50% - 150px);
      }

      @media(max-width: 767px) {
        display: none;
      }
    }


    div.content-wrap {
      padding-right: 75px;

      @media(max-width: 767px) {
        padding-right: 0;
      }

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        padding-right: 15px;
      }

      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        padding-right: 75px;
      }

      h2 {
        text-transform: uppercase;
      }


      div.content-inner {
        ul {

          padding: 0;
          margin: 0;

          li {
            display: inline-block;
            width: 49%;
            vertical-align: top;
            position: relative;
            padding-left: 10px;

            @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
              padding-right: 15px;
            }

            @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
              padding-right: 0;
            }

            &:before {
              content: "\f21b";
              display: inline-block;
              font-family: "Ionicons";
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              text-rendering: auto;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              font-size: 5px;
              vertical-align: middle;
              position: absolute;
              top: 10px;
              left: 0;
            }
          }
        }
      }

    }


    div.mobile-img-wrap {
      margin: 30px -15px 0;
    } //end mobile-img-wrap



    //Image carousel
    div.img-carousel.owl-carousel.owl-theme {

      height: 100%;

      .owl-stage-outer {
        width: 100%;
        height: 100%;

        .owl-stage {
          height: 100%;
        }
      }


      div.owl-item {
        height: 100%;
      }


      div.img-item {

        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;

          @media(max-width: 767px) {
            &:not(.sticker-img) {
              min-height: 320px;
            }
          }
        }


        div.sticker-overlay {
          position: absolute;
          bottom: 30px;
          right: 30px;
          width: 90px;
          height: 90px;

          @media(max-width: 767px) {
            bottom: 15px;
            right: 15px;
            width: 60px;
            height: 60px;
          }

          img {
            width: 100%;
          }
        }


      }//end img-item

    } //end img-carousel



  }//end content-section-1, content-section-2


  section#content-section-2 {

    h2.mobile-heading {
      display: none;
    }

    @media(max-width: 767px) {
      h2:not(.mobile-heading) {
        display: none;
      }
    }

  }//end section#content-section-2


  ul.icons-row {

    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 30px;

    li {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 3);
      text-align: center;

      img {
        width: 100%;
        max-width: 60px;
      }

      span {
        display: block;
        font-size: $fs-sm;
        font-weight: bold;
        margin-top: 8px;
      }
    }

  }//end ul.icons-row


  section#content-row {

    padding-top: 75px;

    @media(max-width: 767px) {
      padding-bottom: 0;
      padding-top: 45px;
    }

    p {
      font-size: $h5-fs;
    }

    div.img-wrap {
      position: relative;
      margin-bottom: 20px;

      img {
        width: 100%;
        max-width: 550px;
      }
    }

  }//end section#content-row


  section#partners {

    padding-top: 75px;
    padding-bottom: 30px;

    @media(max-width: 767px) {
      padding-top: 45px;
    }

    h2 {
      text-transform: uppercase;
      font-size: $h3-fs;
    }

    ul.icons-wrap {
      padding: 0;
      margin: 0;
      list-style: none;

      @media(max-width: 767px) {
        display: inline;
      }

      li {
        display: inline-block;
        max-width: 90px;
        margin: 0 15px 30px;
        vertical-align: middle;

        @media(max-width: 767px) {
          max-width: none;
          width: 49%;
          margin: 0;
          padding: 15px;
        }


        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          @include transition(all $trans);

          @media(max-width: 767px) {
            max-width: 120px;
          }

          &:hover {
            @include prefix(transform, scale(1.25));
          }

        }
      }
    }


  }//end section#partners



















}//end page-template-front-page

































