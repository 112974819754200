
body.single-post {

  background: $off-white;

  header.header {
    background-color: $black !important;
    padding: 15px 0 !important;
  }

  section#blog-post {
    margin-top: 105px;
    padding-top: 0;

    @media(max-width: 767px) {

      margin-top: 60px;

      div.container > div.row > div {
        padding: 0;
      }
    }
  }



  div.single-blog-post {
    background: $white;
    border: solid 1px rgba($grey, .5);
    padding: 15px 15px 30px 15px;
  }



  header.blog-single-header {

    div.img-wrap {
      img {
        height: 350px;
        width: 100%;
        object-fit: cover;
        object-position: center;

        @media(max-width: 767px) {
          height: 210px;
        }
      }
    }

    h1 {
      font-size: $h3-fs;
      text-transform: none;
      font-weight: normal;
      margin-top: 45px;

      @media(max-width: 767px) {
        margin-bottom: 15px;
      }
    }

    div.blog-post-entry-meta {
      font-weight: normal;
      color: $grey;

      @media(max-width: 767px) {
        margin: 0 -15px;
        background: $off-white;
        padding: 5px 15px 0;
      }

      ul.bt-post-meta {

        padding: 0;
        margin: 0 0 45px;
        list-style: none;
        max-width: 65%;

        @media(max-width: 767px) {
          max-width: none;
        }

        li {
          display: inline-block;
          vertical-align: top;
          width: calc(100% / 3);
          height: 24px;

          @media(max-width: 767px) {
            font-size: $fs-xs !important;
          }

          &.post-category {
            @media(max-width: 767px) {

              position: relative;
              top: -1px;

              a {
                font-size: $fs-xs;
              }
            }
          }

          &.post-date, &.post-author {
            font-size: $fs-sm;
            display: inline-block;
            vertical-align: top;
          }

          &.post-date {
            @media(max-width: 767px) {
              text-align: center;
            }
          }

          &.post-author {
            @media(max-width: 767px) {
              text-align: right;
            }
          }

          i {
            vertical-align: top;
            color: rgba($c-primary, .5);
            font-size: $fs-xs;
            position: relative;
            top: 4px;
            margin-right: 5px;

            @media(max-width: 767px) {
              top: 0;
            }
          }
        }
      }

      a.bt-anchor {
        color: rgba($black, .75);
      }
    }
    //end entry meta

  }
  //end blog post header






  div.entry-content {


    h2 {
      font-size: $h4-fs;
      font-family: $ff-copy;
      line-height: 1.4;

      & + p {
        max-width: none;
        margin: 0;
      }
    }


    p + h3 {
      margin-top: 30px;
    }


    h3 {
      font-size: $h5-fs;
      font-weight: bold;
      padding-bottom: 15px;
      margin-bottom: 15px;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: $c-primary;
        max-width: 300px;
      }
    }


    h4 {
      font-size: $p-fs;
      font-weight: bold;
    }


    ul, ol {
      margin-top: 15px;
    }

    p, li {
      a {
        color: $black;
        text-decoration: underline;
        text-decoration-color: $black;

        @include transition(all .3s ease-in-out);

        &:hover {
          text-decoration-color: $c-primary;
        }
      }
    }













































  }//end .entry-content


















}//end single blog post

//single-post


































