
.cta {
  display: inline-block;
  width: 100%;
  text-align: center;
  max-width: 300px;
  padding: 15px 0;
  background: $c-primary;
  color: $white;
  position: relative;

  text-transform: uppercase;
  font-weight: bold;

  font-family: $ff-accent;
  letter-spacing: .5px;
  border: solid 2px $c-primary;

  text-decoration: none!important;
  overflow: hidden;
  z-index: 2;

  @include transition(all .3s ease-in-out);


  @media(max-width: 767px) {
    max-width: 265px;
  }


  &:hover {
    background: $c-primary-hover;
    border-color: $c-primary-hover;
    color: $white;
  }

  &.ghost {
    color: $black;
    background: transparent;

    span {
      position: relative;
      display: block;
      z-index: 2;
      color: inherit;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      bottom: 100%;
      background: $c-primary;
      @include transition(all .185s ease-in-out);
      z-index: 1;
    }

    &:hover {
      background: transparent;
      color: $white;
      border-color: $c-primary;

      span {
        color: $white;
      }

      &:before {
        bottom: 0;
      }
    }


  }//end ghost

  &.black {
    background: $black;
    border-color: $black;

    &:hover {
      background: $pblack;
      border-color: $pblack;
    }
  }


  &.sm {
    padding: 5px;
    font-weight: 400;
    font-size: $fs-xs;
    width: auto;
  }

  &.md {
    padding: 5px;
    font-weight: bold;
    font-size: $fs-xs;
    width: 120px;
  }




  &:not(.ghost) {

    &:hover:after {
      border: solid 1px rgba($white, 0.75);
    }

    &:after {
      content: '';
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      top: 2px;
      left: 2px;
      border: solid 1px rgba($white, 0.15);
      position: absolute;
      transition: all .3s ease-in-out;
    }
  }




}//end cta






























