

section{
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  margin: 0;
  padding: 60px 0;

  @media(max-width: 767px) {
    padding: 45px 0;
  }

}
