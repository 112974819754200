
$theme-name: 'WordPress-Boilerplate';


//Typography settings
$heading-font-name: 'Montserrat';
$copy-font-name: 'Lato';
$accent-font-name: 'Montserrat';


$heading-font-file: 'montserrat-v14-latin-regular';
$copy-font-file: 'lato-v16-latin-regular';
//Typography settings


// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
$section-pad: 90px 0;

$trans: .3s ease-in-out;

