
.owl-theme {

  div.owl-dots {

    margin-top: 15px;

    .owl-dot {

      outline: none!important;

      span {
        background: $c-primary;
        @include opacity(50, .5);
      }

      &.active, &:hover {
        span {
          background: $c-primary;
          @include opacity(100, 1);
        }
      }

    }
  }
}

