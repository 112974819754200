

div.wpcf7 {

  form.wpcf7-form {
    position: relative;


    &.sent {
      div.wpcf7-response-output {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($c-primary, 0.75);
        margin: 0!important;
        border-color: $white!important;
        padding: 15px!important;

        div.cf7-inner {

          position: relative;
          top: 50%;
          @include prefix(transform, translateY(-50%));
          text-align: center;
          text-transform: none;

          p {
            text-transform: none;
            color: $white;
            margin: 0;
            font-size: $h5-fs;
          }

        }//end cf7-inner


      }
    }


    &.invalid {
      div.wpcf7-response-output {
        color: $white;
      }
    }
  }



  //Response styling
  .wpcf7-response-output {

    &.wpcf7-mail-sent-ok {
      background: rgba(56, 56, 56, .5);
      border: solid 1px $white;
      margin-left: 0;
      margin-right: 0;
      text-align: center;
      font-size: $fs-xs;
      font-family: $ff-accent;
      padding: 5px;
    }

    &.wpcf7-mail-sent-ng {
      background: rgba(255, 0, 0, 0.5);
      font-weight: bold;
    }

  }

  span.ajax-loader {
    background-image: url('/wp-content/themes/PortGinestaLandingPage/dist/images/icons/loading-wht.gif');
    width: 24px;
    height: 24px;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px;
    margin: -11px 0 0;
    padding: 0;
    position: absolute;
    top: 50%;
    right: -30px;

    @media(max-width: 767px) {
      right: 15px;
      z-index: 5;
    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      right: 15px;
      z-index: 5;
    }

    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      right: -30px;
    }
  }


  label, input[type="tel"], input[type="email"], input[type="text"], textarea {
    width: 100%;
  }


  input[type="tel"], input[type="email"], input[type="text"], textarea {
    border: none;
    outline: none;
    @include prefix(border-radius, 0);
    background: transparent;
    border-bottom: solid 1px $white;
    color: $white;
    padding: 6px;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $white;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      color: $white;
    }

    &:-ms-input-placeholder { /* IE 10+ */
      color: $white;
    }

    &:-moz-placeholder { /* Firefox 18- */
      color: $white;
    }
  }


  label {
    margin-bottom: 0;
  }


  //Checkbox styles
  div.checkbox-col {

    label {

      padding-left: 15px;

      @media (max-height: 1024px) and (orientation: portrait) {
        padding-left: 30px;
      }

      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        padding-left: 30px;
      }

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        padding-left: 30px;
      }
    }

    span.wpcf7-form-control.wpcf7-checkbox {

      //Hide the cf7 checkbox labels
      span.wpcf7-list-item-label {
        display: none;
      }


      span.wpcf7-list-item.first.last {
        margin: 0;
      }

    }//end span.wpcf7-form-control.wpcf7-checkbox

    label > span {
      color: $white;
      font-size: $fs-sm;

      a {
        color: $white;
        text-decoration: underline;
        text-decoration-color: $white;
      }
    }




    input[type="checkbox"] {
      position: absolute;
      @include opacity(0, 0);
      z-index: -1;
      top: 0;
      left: 2px;
    }

    span.checkbox-wrap {

      position: relative;
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      height: 18px;
      top: -2px;
      margin: 0;
      padding: 0 1px;
      left: -15px;


      @media (max-width: 1220px) {
        position: absolute;
        top: -1px;
        margin-right: 0;
      }

      @media (max-height: 1024px) and (orientation: portrait) {
        left: -30px;
      }

      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        left: -30px;
      }

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        left: -30px;
      }


      &:before, &:after {
        font-family: Ionicons;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        @include transition(all $trans);
        display: block;
      }

      &:before {
        content: "\f401";
      }

      &:after {
        content: "\f122";
        color: $white;
        position: absolute;
        top: 18px;
        font-size: 11px;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        @include opacity(0, 0);
      }



    }//end span.bfb-checkbox


    input[type="checkbox"]:checked {

      & + span.checkbox-wrap {
        &:after {
          top: 4px;
          @include opacity(100, 1);
        }
      }

    }//input checked



    span.wpcf7-form-control-wrap.receive-more-information {
      input[type="checkbox"]:checked {
      & + span.checkbox-wrap {
          &:after {
            top: 4px;
            @include opacity(100, 1);
          }
        }
      }//input checked
    }//end span.checkbox

  }//end checkbox-col


  div.cf7-input-col-6, div.cf7-input-col-12 {
    margin-bottom: 15px;
  }

  div.cf7-input-col-6 {

    display: inline-block;
    width: 50%;

    @media (max-height: 1024px) and (orientation: portrait) {
      display: block;
      width: 100%;
    }

    &.cf7-col-left {
      padding-right: 8px;

      @media (max-height: 1024px) and (orientation: portrait) {
        padding: 0;
      }
    }

    &.cf7-col-right {
      margin-left: -5px;
      padding-left: 7px;

      @media (max-height: 1024px) and (orientation: portrait) {
        padding: 0;
        margin-left: 0;
      }
    }



  }//end .cf7-input-col-6


  div.cf7-input-col-12 {
    width: 100%;
  }//end cf7-input-col-12


  div.submit-wrap {
    text-align: right;
    margin-top: 30px;
    position: relative;

    @media(max-width: 767px) {
      text-align: center;
    }

    input.wpcf7-form-control.wpcf7-submit {
      background: $white;
      border: none;
      outline: none;
      color: $c-primary;
      text-transform: uppercase;
      font-weight: bold;
      padding: 8px 30px;
      @include prefix(border-radius, 30px);
      cursor: pointer;
      @include transition(all $trans);

      &:hover {
        background: $c-primary;
        color: $white;
      }
    }
  }


  span.wpcf7-not-valid-tip {
    display: inline-block;
    font-size: $fs-xs;
    background: rgba($pblack, 0.45);
  }


  //Checkbox col not valid tool tip
  div.checkbox-col {
    span.wpcf7-not-valid-tip {
      position: absolute;
      bottom: -20px;
      left: 27px;
      width: 100%;
      white-space: nowrap;
      min-width: 115px;

      @media (max-height: 1024px) and (orientation: portrait) {
        position: relative;
        bottom: 0;
        left: 0;
        min-width: 0;
        white-space: normal;
      }
    }
  }











}//end wpcf7
