
$white: #fff;
$black: #000;
$pblack: #000;

$grey: #2b2b2b;
$off-white: #F0F0F0;

$c-primary: $black;
$c-primary-hover: lighten($black, 15);


$dark-blue: #222B44;
$bright-blue: #0A1B4F;
$light-blue: #1E5F9A;
$turqouise: #4EB9EC;
$yellow: #F3CC23;

$c-primary: $dark-blue;
$c-primary-hover: $bright-blue;
