@import "common/variables";

/* Headings font - latin */
@font-face {
  font-family: 'Helvetica Neue Condensed Black';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Helvetica Neue Condensed Black.ttf') format('truetype'); /* Legacy iOS */
}


//@font-face {
//  font-family: 'Helvetica Neue';
//  font-style: normal;
//  font-weight: 400;
//  font-display: swap;
//  src: url('../fonts/Helvetica Neue.ttf') format('truetype'); /* Legacy iOS */
//}





@import "../../bower_components/bootstrap/scss/bootstrap.scss";


@import "common/mixins";
@import "common/global";
@import "common/animations";
@import "components/require-components";
@import "layouts/require-layouts";
@import "pages/require-pages";






body {
  @include transition(padding-top $trans);
  padding-top: 85px;

  @media(max-width: 767px) {
    padding-top: 74px;
  }
}






/*WP-Admin Bar*/
body.admin-bar {
  header.header {
    top: 32px;
  }
}
/*WP-Admin Bar*/



