
body.page-default.error404 {

  background: $black;

  header.header {
    background-color: $black !important;
    padding: 15px 0 !important;
  }

  section#page-404 {
    margin-top: 105px;
    padding-top: 0;
    padding-bottom: 0;
    background: $white;

    @media(max-width: 767px) {
      margin-top: 60px;
    }

    .img-wrap {
      position: relative;
      margin-bottom: 75px;

      img {
        width: 270px;
        height: auto;
      }

      i {
        position: absolute;
        color: $white;
        font-size: 60px;
        top: 50%;
        left: 50%;
        @include prefix(transform, translate(-50%, -50%));
      }
    }

    h1 {
      font-size: $h3-fs;
      text-transform: none;
      font-weight: normal;
      margin-top: 45px;
      margin-bottom: 30px;
    }

    h2 {
      font-size: $h4-fs;
      font-family: $ff-copy;
      line-height: 1.4;
      margin-bottom: 15px;

      & + p {
        max-width: none;
        margin: 0;
      }
    }

    p + h3, p + h2 {
      margin-top: 30px;
    }

    h3 {
      font-size: $h5-fs;
      font-weight: bold;
      padding-bottom: 15px;
      margin-bottom: 15px;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: $c-primary;
        max-width: 300px;
      }
    }

    h4 {
      font-size: $p-fs;
      font-weight: bold;
    }

    ul, ol {
      margin-top: 15px;
    }

    p, li {
      a {
        color: $black;
        text-decoration: underline;
        text-decoration-color: $black;

        @include transition(all .3s ease-in-out);

        &:hover {
          text-decoration-color: $c-primary;
        }
      }
    }



  }//end section page default









}//end page default






















