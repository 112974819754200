.mfp-bottom-bar {
  padding: 0 10px !important;
}

.mfp-counter {
  right: 10px !important;
  color: #fff !important;
}

.mfp-bg {
  opacity: 0.8 !important;
}


.mfp-zoom-in {
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }
  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }
  &.mfp-removing {
    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
  }
}